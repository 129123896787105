require('./bootstrap');

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import Cropper from 'cropperjs'
import TomSelect from 'tom-select'

import Chartjs from 'chart.js/auto'
import 'chartjs-adapter-date-fns';
import { nl, enGB } from 'date-fns/locale';

window.locale = { nl, gb: enGB };

import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/anchor'
import route from 'ziggy-js';

// Ziggy
window.route = route;

// Alpine
window.Alpine = Alpine
window.Chartjs = Chartjs

Alpine.plugin(focus)
Alpine.start()

// chartjs


// TOM SELECT on all select elements
var config = {};
const selects = document.getElementsByTagName("select");
for (const select in selects) {
    if (typeof selects[select].id == 'string' && selects[select].id != '') {
        new TomSelect('#' + selects[select].id, config);
    }
}
// END TOM SELECT


// IMAGE CROP
window.onChangeCropImage = function (e) {

    var files = e.target.files;
    var done = function (url) {

        e.target.value = '';
        image.src = url;

        var cropper = new Cropper(image, {
            aspectRatio: 1,
            viewMode: 1,
        });

        document.getElementById('cropButton').addEventListener('click', function (e) {

            var canvas;

            if (cropper) {

                canvas = cropper.getCroppedCanvas({
                    width: 160,
                    height: 160,
                });

                document.getElementById('croppedImage').value = canvas.toDataURL();
                // document.getElementById('imageForm').submit();
            }

        });
    };

    var reader;
    var file;

    if (files && files.length > 0) {
        file = files[0];
        if (URL) {
            done(URL.createObjectURL(file));
        } else if (FileReader) {
            reader = new FileReader();
            reader.onload = function (e) {
                done(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }
};

// when modal closes
//   cropper.destroy();
//   cropper = null;

// END IMAGE CROP

document.addEventListener("DOMContentLoaded", function () {
    tinymce.init({
        selector: `textarea.tinymce`,
        plugins: 'lists',
        toolbar: 'undo redo | styleselect | bold italic | numlist bullist',
        menubar: false,
        width: '100%',
        height: 300
    });

    tinymce.init({
        selector: `textarea.tinymceBig`,
        plugins: 'lists, link, image, anchor',
        toolbar: 'undo redo | styleselect | bold italic | numlist bullist',
        menubar: true,
        width: '100%',
        height: 500,
    });
});

window.addEventListener('csrf-token', ({ detail: token }) => {
    document.head
        .querySelector('meta[name="csrf-token"]')
        .setAttribute('content', token);

    const $_tokens = document.querySelectorAll('input[name="_token"]');

    $_tokens.forEach($_token => {
        $_token.setAttribute('value', token);
    });
})
